import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as ChainLink } from "../../Assets/icons/context-menu-icons/chainLink.svg";
import { ReactComponent as ShareIcon } from "../../Assets/icons/context-menu-icons/share.svg";
import { CircularProgressBar } from "../GenericComponents/CircularProgressBar";
import { useAuthService } from "../../Context/AuthContext";
import copy from "copy-to-clipboard";
import { notifySuccess } from "../../Helpers/Notifications";
import { useLinksquaredService } from "../../Context/LinksquaredContext";

const RefferalModal = ({ handleClose, setLoading }) => {
  const { generateLink } = useLinksquaredService();

  const { user, referrals, getNumberOfReferrals } = useAuthService();

  const [inviteLink, setInviteLink] = useState("");

  const handleShare = () => {
    let title = "Join emotii";
    let message = `${user.name} has invited you to join emotii. Tap the link below and start communicating without boundaries.`;
    copy(`${title}\n${message}\n${inviteLink} `);
    notifySuccess("Link copied");
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        // 👇️ your logic here
        handleClose();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const handleCreateLink = () => {
    setLoading(true);
    generateLink(
      "Join emotii",
      "You're invited to join emotii. Tap the link below and start communicating without boundaries.",
      user,
      (successData) => {
        setLoading(false);
        setInviteLink(successData);
      },
      (errorData) => {
        setLoading(false);
        console.error("Error creating link:", errorData);
      }
    );
  };

  const handleGetReferrals = () => {
    setLoading(true);
    getNumberOfReferrals(
      (response) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    handleGetReferrals();
    handleCreateLink();
  }, []);

  return (
    <FullScreenItem>
      <Card>
        <Line>
          <Title>Referral</Title>
          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Line>

        <Line style={{ justifyContent: "center" }}>
          {referrals > 4 ? (
            <InfoText>
              Congrats, you got <br />
              <InfoTextSpan>
                1 Month of Premium subscription for FREE
              </InfoTextSpan>
            </InfoText>
          ) : (
            <InfoText>
              Invite at least 5 friends and get <br />
              <InfoTextSpan>
                1 Month of Premium subscription for FREE
              </InfoTextSpan>
            </InfoText>
          )}
        </Line>

        <Line style={{ justifyContent: "center" }}>
          <CircularProgressBar
            key={"sdqweqw1"}
            value={referrals}
            maxValue={5}
            size={200}
            strokeWidth={10}
          />
        </Line>

        <Line style={{ flexDirection: "column", gap: "10px" }}>
          <InfoText>Your refferal link</InfoText>
          <CustomInviteInputWrapper>
            <ChainLink />
            <CustomInput
              value={inviteLink}
              readOnly
              onClick={() => handleShare()}
            />
            <InvisibleBtn onClick={() => handleShare()}>
              <ShareIcon />
            </InvisibleBtn>
          </CustomInviteInputWrapper>
        </Line>
      </Card>
    </FullScreenItem>
  );
};

export default RefferalModal;

const FullScreenItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 2;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-width: 500px;
  width: 100%;
  background: ${colors.newChatModalBg};
  border-radius: 8px;
  padding: 50px;
  position: relative;
  gap: 30px;
`;
const Line = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.white};
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  max-width: 32px;
`;
const InfoText = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${colors.modalInfoTextColor};
`;
const InfoTextSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${colors.modalGreenColor};
`;
const CustomInviteInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: ${colors.inputBg};
  width: 100%;
  padding: 15px 20px;
`;
const CustomInput = styled.input`
  width: 100%;
  background: none;
  font-size: 18px;
  line-height: 24px;
  border: none;
  outline: none;
  color: ${colors.modalInfoTextColor};
`;
const InvisibleBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
`;
