import { POST } from "../API";

let source = null;
let sourceSso = null;

export const registerUserApi = (data, onSuccess, onError) => {
    source = POST(`/api/v1/identity/users/register`, data, onSuccess, onError, true, source)
}

export const registerUserWiwthSSOAPICALL = (data, onSuccess, onError) => {
    sourceSso = POST(`/api/v1/identity/users/register_user_with_sso`, data, onSuccess, onError, true, sourceSso)
}
