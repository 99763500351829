import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import LocalStorage from '../Helpers/LocalStorage';
import styled from 'styled-components';
import SpinnerComponent from '../Components/GenericComponents/SpinnerComponent';

const SsoAuthPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false)

    const handleTokens = () => {
        const ssoAccessToken = searchParams.get("token");
        const ssoRefreshToken = searchParams.get("refresh_token");

        if (ssoAccessToken && ssoAccessToken != "") {
            LocalStorage.setSSOFlag(true);
            LocalStorage.setAuthenthicationToken(ssoAccessToken);
            LocalStorage.setRefreshToken(ssoRefreshToken);

        }
    }

    useEffect(() => {
        handleTokens();
    }, [])

    return (
        <SpinnerComponent isLoading={isLoading}>
            <Container>
            </Container>
        </SpinnerComponent>
    )
}

export default SsoAuthPage
const Container = styled.div`
display:flex;
max-height:100vh;
height:100%;
min-height:600px;
width:100%;
align-items:center;
overflow:auto;
position:relative;
`
