
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";


export const CircularProgressBar = ({ value, maxValue, size, strokeWidth }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const gapSize = 3; // Set a gap size between segments

    // Each segment has a visible part (stroke) and a gap
    const segmentCount = maxValue;
    const segmentLength = (circumference / segmentCount) - gapSize;

    return (
        <svg width={size} height={size}>
            {[...Array(segmentCount)].map((_, i) => {
                const segmentOffset = i * (segmentLength + gapSize);
                return (
                    <circle
                        key={i}
                        stroke={"#636363"} // Filled segments
                        fill="transparent"
                        strokeWidth={strokeWidth}
                        r={radius}
                        cx={size / 2}
                        cy={size / 2}
                        strokeDasharray={`${segmentLength} ${circumference}`} // Visible and gap
                        strokeDashoffset={-segmentOffset}
                        strokeLinecap="inherit" // Rounded ends for better look
                        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Start from top
                    />
                );
            })}

            {/* Render each segment */}
            {[...Array(segmentCount)].map((_, i) => {
                const segmentOffset = i * (segmentLength + gapSize);
                return (
                    <circle
                        key={i}
                        stroke={i < value ? "#6c63ff" : "transparent"} // Filled segments
                        fill="transparent"
                        strokeWidth={strokeWidth}
                        r={radius}
                        cx={size / 2}
                        cy={size / 2}
                        strokeDasharray={`${segmentLength} ${circumference}`} // Visible and gap
                        strokeDashoffset={-segmentOffset}
                        strokeLinecap="inherit" // Rounded ends for better look
                        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Start from top
                    />
                );
            })}

            {/* Center text */}
            <text
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill="#fff"
                fontSize="24px"
            >
                {`${value}/${maxValue}`}
            </text>
            <text
                x="50%"
                y="60%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill={colors.modalInfoTextColor}
                fontSize="12px"
            >
                Accepted invites
            </text>
        </svg>
    );
};




const Wrapper = styled.div`
.progress-background {
  stroke: #636363;
}

.progress-circle {
  transition: stroke-dashoffset 0.5s ease;
  stroke: #6c63ff;
}
`