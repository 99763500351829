import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as SearchIcon } from "../../Assets/icons/searchIcon.svg";
import { colors } from '../../Assets/styles/colors';
import { getFlag } from '../../Helpers/FlagHandler';



const LanguageListSelector = ({ openLanguageList, valuesList, setValue, callLanguage }) => {
    const [searchKey, setSearchKey] = useState("");

    const filter = (list, key) => {
        if (key === "") {
            return list
        }

        let found = list.filter(item => item.name.toLowerCase().includes(key.toLowerCase()));

        return found;
    }

    const handleOnClick = (item) => {
        if (callLanguage) {
            setValue(item.country_code)
            openLanguageList();
        } else {
            setValue(item.name);
            openLanguageList();
        }

    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                // 👇️ your logic here
                openLanguageList();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);
    return (
        <FullScreenItem>
            <Container>
                <Header>
                    <SearchLanguage>
                        <SearchIcon />
                        <input
                            placeholder='Search'
                            autoFocus
                            value={searchKey}
                            onChange={(e) => setSearchKey(e.currentTarget.value)} />
                    </SearchLanguage>
                    <CloseBtn>
                        <CloseIcon onClick={openLanguageList} />
                    </CloseBtn>
                </Header>
                <Subtitle>Available languages</Subtitle>
                <ListContainer>
                    {filter(valuesList, searchKey)?.map((item, index) => (
                        <ListItem key={index} onClick={() => handleOnClick(item)}>
                            <Flag src={getFlag(item.country_code)} alt={item.name} />  {item.name} ({item.translation})
                        </ListItem>
                    ))}

                </ListContainer>

            </Container>
        </FullScreenItem>

    )
}

export default LanguageListSelector

const FullScreenItem = styled.div`
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
background:rgba(0,0,0,0.3);
width:100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
z-index:2;
`
const Container = styled.div`
display:flex;
flex-direction:column;
padding:25px;
height:90%;
width:400px;
background:${colors.newChatModalBg};
gap:20px;
border-radius:10px;
`
const Header = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
gap:10px;
`
const CloseBtn = styled.button`
display:flex;
align-items:center;
background:none;
border:none;
cursor:pointer;

`
const SearchLanguage = styled.div`
display:flex;
align-items:center;
gap:5px;
width:100%;
background:rgba(47, 50, 57, 1);
padding:5px 10px;
border-radius:10px;

input{
flex:1;
background:rgba(47, 50, 57, 1);
border:none;
outline:none;
font-size:14px;
color:${colors.white};
line-height:20px;
}
`
const ListContainer = styled.div`
display:flex;
flex-direction:column;
gap:10px;
height:100%;
overflow:auto;
`
const ListItem = styled.h3`
display:flex;
align-items:center;
width:100%;
color:${colors.listColor};
font-size:16px;
line-height:22px;
font-weight:400;
cursor:pointer;
gap:10px;
`
const Flag = styled.img`
width:20px;
height:20px;
display:flex;
object-fit:contain;
`
const Subtitle = styled.p`
color:${colors.white};
font-size:16px;
line-height:20px;
font-weight:400;

`