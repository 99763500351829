import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";


const GenderSelector = ({ openModal, valuesList, setValue, value }) => {

    const handleOnClick = (item) => {
        setValue(item.value);
        openModal();
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                // 👇️ your logic here
                openModal();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <FullScreenItem>
            <Container>
                <Header>
                    <Title>Select a gender</Title>
                    <CloseBtn>
                        <CloseIcon onClick={openModal} />
                    </CloseBtn>
                </Header>
                <ListContainer>
                    {valuesList?.map((item, index) => (
                        <ListItem key={index} onClick={() => handleOnClick(item)}>
                            <Circle selected={item.value === value} />
                            <h3>
                                {item.text}
                            </h3>
                        </ListItem>
                    ))}

                </ListContainer>

            </Container>
        </FullScreenItem>
    )
}

export default GenderSelector

const FullScreenItem = styled.div`
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
background:rgba(0,0,0,0.3);
width:100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
z-index:2;
`
const Container = styled.div`
display:flex;
flex-direction:column;
padding:25px;

width:400px;
background:${colors.newChatModalBg};
gap:20px;
border-radius:10px;
`
const Header = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
gap:10px;
margin-bottom:10px;
`
const CloseBtn = styled.button`
display:flex;
align-items:center;
background:none;
border:none;
cursor:pointer;

`
const Title = styled.h5`
color:${colors.white};
font-size: 24px;
font-weight: 400;
line-height: 28px;
`
const ListContainer = styled.div`
display:flex;
flex-direction:column;
gap:20px;
height:100%;
overflow:auto;
`
const ListItem = styled.div`
display:flex;
align-items:center;
width:100%;
cursor:pointer;
gap:10px;
h3{
color:${colors.white};
font-size:16px;
line-height:22px;
font-weight:400;
}
`
const Circle = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  background-color: none;
  border:1px solid ${colors.white};
  border-radius: 50%;

  ${props => props.selected && `
      background-color: ${colors.white};
    
    `}
`