import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as SmallChat } from "../../Assets/icons/small-chats-icons.svg";
import { ReactComponent as SmallPhone } from "../../Assets/icons/small-phone-icons.svg";
import { ReactComponent as InfoIcon } from "../../Assets/icons/infoIcon.svg";
import { ReactComponent as ChatNameIcon } from "../../Assets/icons/chatNameInputIcon.svg";
import { ReactComponent as RegisterUserImg } from "../../Assets/icons/emptyConversationIcon.svg";
import { genderList } from "../../Constants/DropdownValues";
import BirthdayMenu from "./BirthdayMenu";
import { useAuthService } from "../../Context/AuthContext";
import moment from "moment";
import { useNavigate } from "react-router";
import { notifyError } from "../../Helpers/Notifications";
import { localizationStrings } from "../../Localization/Localization";
import LanguageBtn from "./LanguageBtn";
import GenderBtn from "./GenderBtn";
import CulturalBgInfoModal from "./CulturalBgInfoModal";
import { useLinksquaredService } from "../../Context/LinksquaredContext";
import LocalStorage from "../../Helpers/LocalStorage";

const CreateNewUser = ({ countryCode, setIsLoading }) => {
  const branch = require("branch-sdk");

  const [infoCounter, setInfoCounter] = useState(1);
  const [name, setName] = useState("");
  const [language, setLanguage] = useState("");
  const [callLanguage, setCallLanguage] = useState("");
  const [culturalBg, setCulturalBg] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [branchData, setBranchData] = useState(null);
  const [isCulturalBgModalOpen, setIsCulturalBgModalOpen] = useState(false);
  const { getInvitedBy } = useLinksquaredService();

  const {
    createUser,
    translationsLanguages,
    callTranslationsLanguages,
    pushToken,
  } = useAuthService();
  const navigate = useNavigate();

  const handleContinue = () => {
    setInfoCounter(infoCounter + 1);
  };

  const handleNameChange = (e) => {
    const { value } = e.currentTarget;
    setName(value);
  };

  const handleCulturalBgChange = (e) => {
    const { value } = e.currentTarget;
    setCulturalBg(value);
  };

  const openCulturalBgInfo = () => {
    setIsCulturalBgModalOpen(!isCulturalBgModalOpen);
  };

  const handleDontWantToShare = () => {
    setGender("");
    setCulturalBg("");
    setBirthday("");
    handleContinue();
  };

  const getBranchData = () => {
    branch.data((err, data) => {
      setBranchData(data);
    });
  };

  const handleCreateUser = (
    name,
    birthdate,
    language,
    callLanguage,
    gender,
    cultural_background,
    countryCode
  ) => {
    setIsLoading(true);
    let date = null;
    const dataObject = {
      name: name,
      language: language,
      call_language: callLanguage,
      country_code: countryCode,
    };

    if (pushToken) {
      dataObject.push_token_platform = "web";
      dataObject.push_token = pushToken;
    }

    if (birthdate) {
      date = moment(birthdate).format("yyyy-MM-DD");
    }

    if (gender !== "") {
      dataObject.gender = gender;
    }

    if (cultural_background !== "") {
      dataObject.cultural_background = cultural_background;
    }

    if (date != null) {
      dataObject.birthdate = date;
    }

    if (branchData && branchData?.data_parsed?.invited_by) {
      dataObject.invited_by = branchData.data_parsed.invited_by;
    }

    getInvitedByFromLinksquared((invited_by) => {
      if (invited_by != null) {
        dataObject.invited_by = invited_by;
      }

      createUser(
        dataObject,
        function (response) {
          setInfoCounter(1);
          setIsLoading(false);
          navigate("/");
        },
        function (error) {
          notifyError(`${localizationStrings.profile.something_wrong}`);
          setIsLoading(false);
          setInfoCounter(1);
          navigate("/");
          console.log(error);
        }
      );
    });
  };

  const calculateUploadProgress = (uploadedFiles, totalFiles) => {
    return (uploadedFiles * 100) / totalFiles;
  };

  const getInvitedByFromLinksquared = (completion) => {
    const invited_by = getInvitedBy();
    completion(invited_by);
  };

  useEffect(() => {
    if (infoCounter === 3) {
      handleCreateUser(
        name,
        birthday,
        language,
        callLanguage,
        gender,
        culturalBg,
        countryCode
      );
    }
  }, [infoCounter]);

  useEffect(() => {
    getBranchData();
  }, []);

  return (
    <Container>
      {isCulturalBgModalOpen && (
        <CulturalBgInfoModal openModal={openCulturalBgInfo} />
      )}

      <ProgressBarWrapper>
        <ProgressBar completed={calculateUploadProgress(infoCounter, 2)} />
      </ProgressBarWrapper>

      <Content>
        {infoCounter === 1 && (
          <Card>
            <IconWrapper>
              <RegisterUserImg />
            </IconWrapper>
            <GroupTitle>
              <h1>Chat and calls preference</h1>
              <p>{localizationStrings.register.language_details}</p>
            </GroupTitle>

            <Group>
              <GroupDescription>
                <p>Chat language</p>
              </GroupDescription>
              <LanguageBtn
                value={language}
                setValue={setLanguage}
                valuesList={translationsLanguages}
                placeholder={"Select chat preferred language"}
                placeholderIcon={<SmallChat />}
              />
            </Group>
            <Group>
              <GroupDescription>
                <p>Calls language</p>
              </GroupDescription>
              <LanguageBtn
                value={callLanguage}
                setValue={setCallLanguage}
                valuesList={callTranslationsLanguages}
                placeholder={"Select calls preferred language"}
                placeholderIcon={<SmallPhone />}
                callLanguage
              />
            </Group>
            <Group>
              <GroupDescription>
                <p>Your name</p>
              </GroupDescription>
              <CustomInputWrapper>
                <ChatNameIcon />
                <input
                  placeholder={localizationStrings.register.name_placeholder}
                  onChange={(e) => handleNameChange(e)}
                  value={name}
                  name="name"
                  maxLength={100}
                />
              </CustomInputWrapper>
            </Group>

            <Group>
              <LoginButton
                disabled={
                  name.length < 3 || language === "" || callLanguage === ""
                }
                id="name-continue-button"
                onClick={(e) => handleContinue(e)}
              >
                {localizationStrings.register.next_button}
              </LoginButton>
            </Group>
          </Card>
        )}

        {infoCounter === 2 && (
          <Card>
            <IconWrapper>
              <RegisterUserImg />
            </IconWrapper>
            <GroupTitle>
              <h1>{localizationStrings.register.personal_details}</h1>
              <p>{localizationStrings.register.personal_details_text}</p>
            </GroupTitle>
            <Group>
              <GroupDescription>
                <p>Type or select your birthdate</p>
              </GroupDescription>
              <BirthdayMenu
                value={birthday}
                setValue={setBirthday}
                onboardStyle
              />
              <GroupDescription>
                <p>Your gender</p>
              </GroupDescription>
              <GenderBtn
                value={gender}
                setValue={setGender}
                valuesList={genderList}
                placeholder={localizationStrings.profile.please_select_gender}
              />

              <GroupDescription>
                <p>Cultural background</p>
              </GroupDescription>
              <CulturalBgWrapper>
                <InfoTogle onClick={openCulturalBgInfo}>
                  <InfoIcon />
                </InfoTogle>
                <CustomTextArea
                  resize={"none"}
                  placeholder={
                    localizationStrings.profile.type_cultural_background
                  }
                  onChange={(e) => handleCulturalBgChange(e)}
                  value={culturalBg}
                  name="culturalBg"
                  maxLength={1000}
                ></CustomTextArea>
              </CulturalBgWrapper>
            </Group>
            <Group>
              <DontWantShare>
                <p onClick={() => handleDontWantToShare()}>
                  {localizationStrings.register.i_will_complete_later}
                </p>
              </DontWantShare>
            </Group>
            <Group>
              <LoginButton
                disabled={birthday === "" && gender === "" && culturalBg === ""}
                id="birthday-continue-button"
                onClick={(e) => handleContinue(e)}
              >
                {localizationStrings.register.finish}
              </LoginButton>
            </Group>
          </Card>
        )}
      </Content>
    </Container>
  );
};

export default CreateNewUser;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  height: 100%;
  gap: 20px;
  margin-bottom: auto;
  margin-top: auto;
  margin: 20px;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  h1 {
    color: ${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: left;
  }
  p {
    color: #ffffff80;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
const CustomInputWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.newChatModalInputBg};
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  gap: 10px;

  input {
    width: 100%;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;
const GroupTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  h1 {
    color: ${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: center;
  }
  p {
    color: #ffffff80;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
  }
`;

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  &:disabled {
    cursor: inherit;
    opacity: 0.3;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 32px;
  overflow: auto;
`;

const DontWantShare = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
margin:20px 0;
  p {
    cursor: pointer;
    color: ${colors.purple};
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
  }
`;
const ProgressBarWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #403e7d;
`;
const ProgressBar = styled.div`
  height: 2px;
  background: #7069fd;
  width: ${(props) => props.completed}%;
  transition: 200ms all linear;
`;

const GroupDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: -10px;
`;

const CustomTextArea = styled.textarea`
padding:16px 40px 16px 16px;
border-radius:10px;
height:150px;
 width: 100%;
 resize:none;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }

`;
const CulturalBgWrapper = styled.div`
  position: relative;
`;
const InfoTogle = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  margin: 0 auto;
  svg {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;
