import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { benefitsList } from './SubscriptionPlanPicker'
import { ReactComponent as CheckSvg } from "../../Assets/icons/subscription/check.svg";
import moment from 'moment';

const BenefitElement = ({ title, subtitle }) => {
    return (

        <BenefitWrapper>
            <TextWrapper>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
            </TextWrapper>

            <CheckWrapper>
                <CheckSvg />
            </CheckWrapper>
        </BenefitWrapper>
    )
}

const SubscribedPlanCard = ({ subscription }) => {

    const [price, setPrice] = useState("");
    const [priceTpye, setPriceType] = useState("");
    const [referralPrice, setReferralPrice] = useState(false)


    let willRenew = subscription.willRenew
    let expirationDate = subscription.expirationDate.toString()
    let nextPayment = moment(expirationDate).format("DD.MM.YYYY")
    let productId = subscription.productIdentifier

    const checkPrice = (productId) => {

        if (productId === "prod_QGzOUUfUj5dpQr" || productId === "prod_QMul12YakUO7gP") {
            setPrice("0.99")
            setPriceType("Monthly")
            setReferralPrice(false)
        } else if (productId === "prod_R3Mf48xB1kH99u" || productId === "prod_R3Mo2q1e3ec0Kh") {
            setPrice("9.99")
            setPriceType("Yearly")
            setReferralPrice(false)
        } else {
            setPrice("One month FREE")
            setReferralPrice(true)
        }

        // if (productId === "rc_promo_Referrals_weekly") {
        //     setPrice("One month FREE")
        //     setReferralPrice(true)
        // }


    }

    useEffect(() => {
        checkPrice(productId);
    }, [subscription])

    return (
        <Wrapper>
            <Price>

                {referralPrice ?
                    <RefferalPriceInfo>
                        <span>Referral campaign remaining </span><br />
                        {price}
                    </RefferalPriceInfo>
                    : <PriceInfo>
                        $ {price} {priceTpye && <span>/{priceTpye}</span>}
                    </PriceInfo>}

                <PaymentInfo>
                    <Subtitle style={{ textAlign: "end" }}>{willRenew ? "Next payment" : "Active until"}</Subtitle>
                    <Subtitle style={{ textAlign: "end" }}>{nextPayment}</Subtitle>
                </PaymentInfo>
            </Price>

            <Separator />

            <Bnefits>
                <BenefitsTitle>
                    Your Benefits
                </BenefitsTitle>
                <BenefitsList>
                    {benefitsList.map((item, index) => (
                        <BenefitElement key={index} title={item.title} subtitle={item.subtitle} />
                    ))}

                </BenefitsList>
            </Bnefits>
        </Wrapper>
    )
}

export default SubscribedPlanCard

const Wrapper = styled.div`
display:flex;
flex-direction:column;
padding:20px;
border-radius:12px;
background:${colors.modalDarkPurple};
box-shadow: 0px 0px 11.3px 0px rgba(0, 0, 0, 0.4);
gap:20px;
border:1px solid ${colors.purple};
`

const Separator = styled.div`
width:100%;
background:rgba(46, 50, 62, 1);
min-height:1px;
margin:10px 0;
`
const Price = styled.div`
display:flex;
align-items:center;
`
const PriceInfo = styled.div`
width:100%;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color:${colors.modalGreenColor};
span{
font-weight:400;
font-size:16px;
width:100%;
`
const PaymentInfo = styled.div`
min-width:80px;
`
const Bnefits = styled.div`
display:flex;
flex-direction:column;
gap:20px;
`

const BenefitsTitle = styled.h3`
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.modalInfoTextColor};
`
const BenefitsList = styled.div`
display:flex;
flex-direction:column;
gap:20px;
`
const BenefitWrapper = styled.div`
display:flex;
align-items:center;
width:100%;
gap:30px;
padding:10px 0;
`
const TextWrapper = styled.div`
display:flex;
flex-direction:column;
gap:5px;
width:100%;

`
const CheckWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
`
const Title = styled.h3`
font-size: 14px;
font-weight: 600;
line-height: 16px;
text-align: left;
color:${colors.white};
`
const Subtitle = styled.p`
font-size: 12px;
font-weight: 400;
line-height: 14px;
text-align: left;
color:${colors.modalInfoTextColor};
`
const RefferalPriceInfo = styled(PriceInfo)`
font-size:16px;
line-height:20px;
span{
color:${colors.modalInfoTextColor};
font-size:12px;

}
`
