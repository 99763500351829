import React from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import BenefitElement from './BenefitElement';
import { SubscriptionPlan } from './SubscriptionPlan';
import { ReactComponent as Crown } from "../../Assets/icons/subscription/crown.svg";
export const benefitsList = [

    {
        title: "HD AUDIO Calls",
        subtitle: "Enjoy high-quality audio calls.",
        free: false,
        pro: true,
    },
    {
        title: "Live Audio Translations",
        subtitle: "Speak your language, and other person hears you in theirs.",
        free: false,
        pro: true,
    },
    {
        title: "No Language Barriers",
        subtitle: "Chat effortlessly with anyone, regardless of language",
        free: true,
        pro: true,
    }
]

const SubscriptionPlanPicker = ({ subscribeTo, setSubscribeTo }) => {

    const subscriptionPlans = [
        {
            price: "0.99",
            payment: "Month",
            aditionalInfo: "(After trial)",
            value: "monthly",
            handleClick: () => setSubscribeTo("monthly")
        },
        {
            price: "9.99",
            priceAditionalInfo: "$ 11.88",
            payment: "Year",
            aditionalInfo: "Save",
            aditionalInfoOffer: "15%",
            value: "yearly",
            handleClick: () => setSubscribeTo("yearly")
        },
        {
            differentOffer: true,
            price: "One month FREE",
            priceAditionalInfo: "Invite 5 friends to get",
            aditionalInfo: "Save",
            aditionalInfoOffer: "100%",
            value: "invite",
            handleClick: () => setSubscribeTo("invite")
        },
    ]



    return (
        <InsideCard>
            <Title>PRO SUBSCRIPTION PLAN</Title>
            <SubscriptionPlanList>
                {subscriptionPlans.map((item, index) => (
                    <SubscriptionPlan
                        key={index}
                        price={item.price}
                        aditionalInfo={item.aditionalInfo}
                        aditionalInfoOffer={item.aditionalInfoOffer}
                        priceAditionalInfo={item.priceAditionalInfo}
                        payment={item.payment}
                        handleClick={item.handleClick}
                        selectedValue={subscribeTo}
                        value={item.value}
                        differentOffer={item.differentOffer}
                    />
                ))}
            </SubscriptionPlanList>
            <Separator />
            <Line style={{ gap: "0px" }}>
                <InfoText style={{ minWidth: "200px" }}>Benefits</InfoText>
                <MarkCell>
                    <BenefitsHeaderTitle>
                        FREE
                    </BenefitsHeaderTitle>

                </MarkCell>
                <MarkCell>
                    <Crown />
                    <BenefitsHeaderTitle>

                        PRO
                    </BenefitsHeaderTitle>
                </MarkCell>
            </Line>
            <BenefitsList>
                {benefitsList.map((item, index) => (
                    <BenefitElement key={index} title={item.title} subtitle={item.subtitle} free={item.free} pro={item.pro} />
                ))}
            </BenefitsList>


        </InsideCard>
    )
}

export default SubscriptionPlanPicker
const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;


const SubscriptionPlanList = styled.div`
display:flex;
flex-direction:column;
gap:15px;
`
const MarkCell = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
padding:5px 10px;
border-left:1px solid rgba(46, 50, 62, 1);
gap:10px;
`
const BenefitsHeaderTitle = styled.h3`
font-size:14px;
font-weight:600;
line-height:22px;
color:${colors.white};
`
const InsideCard = styled.div`
display:flex;
flex-direction:column;
padding:20px;
border-radius:12px;
background:${colors.modalDarkPurple};
box-shadow: 0px 0px 11.3px 0px rgba(0, 0, 0, 0.4);
gap:20px;
`
const Separator = styled.div`
width:100%;
background:rgba(46, 50, 62, 1);
min-height:1px;
margin:10px 0;
`
const Line = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  svg {
    max-height: 40px;
    width: fit-content;
  }
`;
const InfoText = styled.h1`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${colors.chatElementColor};
`;
const Title = styled.h3`
text-align:center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${colors.white};
`