import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as AvatarUnset } from "../../Assets/icons/unsetAvatar.svg";
import { ReactComponent as ProBadge } from "../../Assets/icons/subscription/crown.svg";
import { ReactComponent as FreeBadge } from "../../Assets/icons/subscription/free.svg";
import { ReactComponent as Bell } from "../../Assets/icons/linksquaredBell.svg";
import NewChatButton from "./NewChatButton";
import { useAuthService } from "../../Context/AuthContext";
import { useLinksquaredService } from "../../Context/LinksquaredContext";

const UserMenu = ({
  setIsProfileOpen,
  setIsAddNewChatGroupOpen,
  setIsAddNewChatOpen,
  setIsAddQuickChatOpen,
  handleOpenLinksuaredMessages,
}) => {

  const { user } = useAuthService();
  const { linksquaredUnread } = useLinksquaredService();

  return (
    <Container>
      <UserAvatar>
        {user.profile_picture ? (
          <CustomAvatar
            src={user.profile_picture}
            alt="avatar"
            onClick={() => setIsProfileOpen(true)}
          />
        ) : (
          <AvatarUnset onClick={() => setIsProfileOpen(true)} />
        )}
        <ProBadgeWrapper>
          {user.is_pro ? <ProBadge /> : <FreeBadge />}
        </ProBadgeWrapper>
      </UserAvatar>

      <LinkSquaredNotificationBadge
        onClick={() => handleOpenLinksuaredMessages()}
      >
        <Bell />
        {linksquaredUnread > 0 && (
          <UnreadNumber>
            <p> {linksquaredUnread}</p>
          </UnreadNumber>
        )}
      </LinkSquaredNotificationBadge>

      <div style={{ marginLeft: "auto" }}>
        <NewChatButton
          setIsAddNewChatGroupOpen={setIsAddNewChatGroupOpen}
          setIsAddNewChatOpen={setIsAddNewChatOpen}
          setIsAddQuickChatOpen={setIsAddQuickChatOpen}
        />
      </div>
    </Container>
  );
};

export default UserMenu;
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: ${colors.userMenuHeaderBg};
  gap: 20px;
`;
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  min-height: 42px;
  object-fit: contain;
  position: relative;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }

  // img{
  //      object-fit:cover;
  //     height:42px;
  //     width:42px;
  //      position:relative;

  // }
`;

const ProBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -5px;
  right: -3px;
`;
const CustomAvatar = styled.img`
  border-radius: 100px;
  width: 100%;
  object-fit: cover;
  height: 40px;
  width: 40px;
  position: relative;
`;
const LinkSquaredNotificationBadge = styled.div`
  margin-top: 8px;
  cursor: pointer;
  position: relative;
`;
const UnreadNumber = styled.div`
  display: flex;
  background: red;
  border-radius: 50px;
  min-height: 15px;
  min-width: 15px;
  position: absolute;
  top: -5px;
  right: -5px;
  align-items: center;
  justify-content: center;

  p {
    display: flex;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 600;
    color: white;
  }
`;
