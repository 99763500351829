
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as RightArrow } from "../../Assets/icons/dropDownArrow.svg";
import GenderSelector from './GenderSelector';


const GenderBtn = ({ value, setValue, placeholder, valuesList }) => {

    const foundValue = valuesList?.find(item => item.value === value)

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    return (
        <Container>
            {isModalOpen &&
                <GenderSelector
                    setValue={setValue}
                    valuesList={valuesList}
                    openModal={openModal}
                    value={value}
                />
            }
            <ButtonContainer
                onClick={openModal}>
                {value ? (
                    <>
                        <ValueName>{foundValue.text}</ValueName>
                        <RightArrow style={{ marginLeft: "auto", width: "14px" }} />
                    </>
                )
                    : (
                        <>
                            <ValueName disabled>{placeholder}</ValueName>
                            <RightArrow style={{ marginLeft: "auto", width: "14px" }} />
                        </>
                    )
                }


            </ButtonContainer>

        </Container>


    )
}

export default GenderBtn

const Container = styled.div`

`
const ButtonContainer = styled.div`
display:flex;
align-items:center;
gap:10px;
padding:16px;
  border-radius: 10px;
  background: ${colors.newChatModalInputBg};
cursor:pointer;
`
const ValueName = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: ${colors.white};


${props => props.disabled && (
        `
    opacity:0.3;

    `
    )}

     `