import React, { useState } from "react";
import styled from "styled-components";

import { filterConversations } from "../../Helpers/filters/filterConversations";
import CallListElement from "../AudioVideoCalls/CallListElement";
import SearchMenu from "../GenericComponents/SearchMenu";
import CallListContextMenu from "./CallListContextMenu";
import DeleteConfirmationChild from "../GenericComponents/DeleteConfirmationChild";
import { useContextMenu } from "react-contexify";
import { useCallsService } from "../../Context/CallsContext";
import { notifyError } from "../../Helpers/Notifications";
import { localizationStrings } from "../../Localization/Localization";
import LocalStorage from "../../Helpers/LocalStorage";
import FirstTimeUsingMessage from "../GenericComponents/FirstTimeUsingMessage";

const CallsList = ({
  searchInput,
  setSearchInput,
  calls,
  selectedCall,
  handleSelectCall,
  loadCalls,
  setLoading,
}) => {
  const [selectedContextMenuItem, setSelectedContextMenuItem] = useState(null);
  const { deleteCall } = useCallsService();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const MENU_ID = "callList-id";

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function displayMenu(e, call) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    setSelectedContextMenuItem(call);

    show({
      event: e,
    });
  }

  const handleDeleteCall = (callId) => {
    setLoading(true);
    deleteCall(
      callId,
      (response) => {
        setLoading(false);
        loadCalls();
      },
      (error) => {
        notifyError(localizationStrings.profile.something_wrong);
        setLoading(false);
      }
    );
  };

  return (
    <ChatsList>
      <DeleteConfirmationChild
        setOpen={setDeleteConfirmationOpen}
        open={deleteConfirmationOpen}
        action={handleDeleteCall}
        item={selectedContextMenuItem?.uuid}
        variant={"delete_call"}
      />

      <CallListContextMenu
        selectedContextMenuItem={selectedContextMenuItem}
        setDeleteConfirmationOpen={setDeleteConfirmationOpen}
      />

      <SearchMenu value={searchInput} setvalue={setSearchInput} />

      {filterConversations(calls, searchInput).map((call) => (
        <CallListElement
          key={call.id}
          data={call}
          selected={selectedCall?.id === call.id}
          handleSelectCall={handleSelectCall}
          displayMenu={displayMenu}
        />
      ))}
    </ChatsList>
  );
};

export default CallsList;

const ChatsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 10px 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;
