import { GET } from "../API"

export const getContactsAPI = (onSuccess, onError) => {
    GET(`/api/v1/chat/contacts`, onSuccess, onError)
}

export const getContactsOnPlatformForCurrentUserAPI = (onSuccess, onError) => {
    GET(`/api/v1/chat/contacts/on_platform_for_current_user`, onSuccess, onError)
}

