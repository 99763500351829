import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { useAuthService } from "../Context/AuthContext";
import SpinnerComponent from "../Components/GenericComponents/SpinnerComponent";
import { localizationStrings } from "../Localization/Localization";
import { colors } from "../Assets/styles/colors";
import { ReactComponent as MicrosoftLogo } from "../Assets/icons/logo/microsoftLogo.svg";
import { ReactComponent as GoogleLogo } from "../Assets/icons/logo/google.svg";

import "../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { ReactComponent as PhoneInputIcon } from "../Assets/icons/enterPhoneNumberIcon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import LocalStorage from "../Helpers/LocalStorage";
import { notifyError } from "../Helpers/Notifications";
import { useLinksquaredService } from "../Context/LinksquaredContext";

const LoginPage = ({ appVerifier }) => {
  const isElectron = !!window.electron;
  const { getInvitedBy } = useLinksquaredService();

  const navigateInNewTab = (to) => {
    window.open("https://www.emotii.ai/terms");
  };

  const WEB_REDIRECT_URL = process.env.REACT_APP_ELECTRON_TO_WEB_URL;

  const { getCurrentUser, getSSoLoginLink, getGoogleSSoLoginLink } =
    useAuthService();
  const { signInWithCustomAcessToken } = useAuthService();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const onBtnClick = () => {
    if (
      window.electron &&
      typeof window.electron.openExternalUrl === "function"
    ) {
      window.electron.openExternalUrl(WEB_REDIRECT_URL);
    } else {
      console.error("Electron API is not available or function not defined");
    }
  };

  const onLoginMiscrosoftSubmit = () => {
    LocalStorage.setSSOFlag(true);

    // This will set the cookie
    getInvitedBy();

    getSSoLoginLink(
      (response) => {
        const microsoftLink = response.data.redirect_url;
        // window.location = microsoftLink
        if (
          window.electron &&
          typeof window.electron.openMicrofostLoginWindow === "function"
        ) {
          window.electron.openMicrofostLoginWindow(microsoftLink);
        } else {
          console.error(
            "Electron API is not available or function not defined"
          );
        }
      },
      (error) => {
        notifyError(`${localizationStrings.profile.something_wrong}`);
      }
    );
  };

  const onLoginGoogleSubmit = () => {
    LocalStorage.setSSOFlag(true);
    // This will set the cookie
    getInvitedBy();

    getGoogleSSoLoginLink(
      (response) => {
        const googleLink = response.data.redirect_url;

        if (
          window.electron &&
          typeof window.electron.openMicrofostLoginWindow === "function"
        ) {
          window.electron.openMicrofostLoginWindow(googleLink);
        } else {
          console.error(
            "Electron API is not available or function not defined"
          );
        }
      },
      (error) => {
        notifyError(`${localizationStrings.profile.something_wrong}`);
      }
    );
  };

  useEffect(() => {
    // Ensure `window.electron` is available
    if (isElectron) {
      const handleDeepLink = (url) => {
        const receivedUrl = new URL(url);
        const params = new URLSearchParams(receivedUrl.searchParams);
        const access_token = params.get("access_token");
        if (access_token && access_token != "") {
          setIsLoading(true);
          signInWithCustomAcessToken(
            access_token,
            (response) => {
              getCurrentUser(
                () => { },
                () => { }
              );
              setIsLoading(false);
            },
            (error) => {
              console.log(error);
              setIsLoading(false);
            }
          );
        }
      };

      if (window.electron && typeof window.electron.onDeepLink === "function") {
        window.electron.onDeepLink(handleDeepLink);
        return () => {
          window.electron.onDeepLink(() => { });
        };
      }
    } else {
      console.error("Electron API not available");
    }
  }, []);

  useEffect(() => {
    if (isElectron) {
      const handleResponseFromSSOAuthPage = (response) => {
        LocalStorage.setSSOFlag(true);
        LocalStorage.setAuthenthicationToken(response.token);
        LocalStorage.setRefreshToken(response.refresh_token);
        getCurrentUser(
          (response) => {
            const userResponse = response.data.user;
            if (userResponse.language != null) {
              navigate("/");
            } else {
              navigate("/onboarding");
            }
          },
          (error) => {
            console.log(error);
          }
        );
      };
      if (
        window.electron &&
        typeof window.electron.responseFromSSoAuthWindow === "function"
      ) {
        window.electron.responseFromSSoAuthWindow(
          handleResponseFromSSOAuthPage
        );
        return () => {
          window.electron.responseFromSSoAuthWindow(() => { });
        };
      }
    } else {
      console.error("Electron API not available");
    }
  }, []);

  return (
    <SpinnerComponent isLoading={isLoading}>
      <FirstWrapper>
        <LoginPageContainer>
          <Container>
            <Content>
              <Card>
                <Group>
                  <h1 style={{ marginBottom: "20px" }}>
                    {localizationStrings.login.login}
                  </h1>
                  <LoginButton
                    id="sign-in-button"
                    onClick={(e) => onBtnClick(e)}
                  >
                    {localizationStrings.login.authenticate}
                  </LoginButton>
                  <Line style={{ justifyContent: "center" }}>
                    <Description>or continue with</Description>
                  </Line>
                  <LoginButton
                    id="sign-in-sso"
                    onClick={(e) => onLoginMiscrosoftSubmit(e)}
                  >
                    <MicrosoftLogo />
                    {localizationStrings.login.loginSSO}
                  </LoginButton>
                  <LoginButton
                    id="sign-in-sso"
                    onClick={(e) => onLoginGoogleSubmit(e)}
                  >
                    <GoogleLogo />
                    {localizationStrings.login.loginGoogleSSO}
                  </LoginButton>
                  <TermsInfo
                    onClick={() => navigateInNewTab("terms-and-conditions")}
                  >
                    <p>
                      {localizationStrings.login.login_agree}
                      <br />
                      <span>
                        {localizationStrings.login.terms_and_conditions}
                      </span>
                    </p>
                  </TermsInfo>
                </Group>
              </Card>
            </Content>
          </Container>
        </LoginPageContainer>
      </FirstWrapper>
    </SpinnerComponent>
  );
};

export default LoginPage;
const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const FirstWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  @media (max-width: 1440px) {
    margin: 0;
    padding: 0;
  }
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  gap: 20px;
  margin-top: auto;
  margin-bottom: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  h1 {
    color: ${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: center;
  }
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;
const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 32px;
`;

const TermsInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #b5b8cd;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }

  span {
    cursor: pointer;
    color: ${colors.purple};
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    text-decoration: underline;
  }
`;

const Line = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;
const Description = styled.p`
  color: #ffffff80;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
`;
