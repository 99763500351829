import React from "react";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as PhoneInputIcon } from "../../Assets/icons/enterPhoneNumberIcon.svg";
import { ReactComponent as RightArrow } from "../../Assets/icons/rightArrowLong.svg";
import { ReactComponent as MicrosoftLogo } from "../../Assets/icons/logo/microsoftLogo.svg";
import { ReactComponent as GoogleLogo } from "../../Assets/icons/logo/google.svg";
import { localizationStrings } from "../../Localization/Localization";
import { useSearchParams } from "react-router-dom";

const EnterPhoneNumber = ({
  phone,
  setPhone,
  setCountryCode,
  onSigninSubmit,
  defaultCountry,
  onLoginMiscrosoftSubmit,
  onLoginGoogleSubmit
}) => {

  const navigateInNewTab = (to) => {
    // let url = window.location.origin
    // window.open(`${url}/${to}`)
    window.open("https://www.emotii.ai/terms");
  };

  const [searchParams] = useSearchParams()

  return (
    <Container>
      <LeftSide>
        <Card>
          <IconWrapper>
            <PhoneInputIcon />
          </IconWrapper>

          <Group>
            <Group style={{ gap: "10px", marginBottom: "20px", alignItems: "center" }}>
              <h1>{localizationStrings.login.login}</h1>
              {!searchParams.get("desktop_login") && <p>{localizationStrings.login.select_login_method}</p>}
            </Group>
            <Line>
              <CustomPhoneWrapper>
                <PhoneInput
                  international
                  defaultCountry={defaultCountry}
                  name="phoneNumber"
                  placeholder={localizationStrings.profile.phone_number}
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  onCountryChange={(e) => setCountryCode(e)}
                  limitMaxLength
                />

              </CustomPhoneWrapper>

              <LoginButton
                disabled={!phone || phone?.length < 8}
                id="sign-in-button"
                onClick={(e) => onSigninSubmit(e)}
              >
                <RightArrow />
              </LoginButton>
            </Line>
            {!searchParams.get("desktop_login") &&
              <Line style={{ justifyContent: "center" }}>
                <p>or continue with</p>
              </Line>
            }
            {!searchParams.get("desktop_login") &&
              <LoginButton
                id="sign-in-sso"
                onClick={(e) => onLoginMiscrosoftSubmit(e)}
              >
                <MicrosoftLogo />
                {localizationStrings.login.loginSSO}
              </LoginButton>
            }
            {!searchParams.get("desktop_login") &&
              <LoginButton
                id="sign-in-sso-google"
                onClick={(e) => onLoginGoogleSubmit(e)}
              >
                <GoogleLogo />
                {localizationStrings.login.loginGoogleSSO}
              </LoginButton>
            }
            <TermsInfo onClick={() => navigateInNewTab("terms-and-conditions")}>
              <p>
                {localizationStrings.login.login_agree}
                <br />
                <span>{localizationStrings.login.terms_and_conditions}</span>
              </p>
            </TermsInfo>
          </Group>
        </Card>
      </LeftSide>
    </Container>
  );
};

export default EnterPhoneNumber;
const Container = styled.div`
  display: flex;
  min-height: 600px;
  // max-height: 100vh;
  height: 100%;
  width: 100%;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  gap: 20px;
`;
const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  padding: 0 0 0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width:100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    color: ${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: center;
  }
     p{
    color:#FFFFFF80;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    }
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;
const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
const LeftSide = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
`;
const RightSide = styled(LeftSide)``;

const TermsInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
      margin-top:20px;
  p {
    color: #b5b8cd;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }

  span {
    cursor: pointer;
    color: ${colors.purple};
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    text-decoration: underline;
  }
`;

const Line = styled.div`
display:flex;
gap:10px;
align-items:center;
width:100%;
`

const IconWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
max-width:300px;
max-height:300px;
overflow:hidden;
margin:auto;
svg{
display:flex;
width:100%;
height:100%;
}
`