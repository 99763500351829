import { DELETE, GET, POST, POST_BLOB } from "../API"
let source;
export const createCallApiCall = (other_user_id, onSuccess, onError) => {
    const data = {
        other_user_id: other_user_id
    }
    POST(`/api/v1/chat/calls/create`, data, onSuccess, onError)
}

export const getCallDetailsApiCall = (uuid, onSuccess, onError) => {
    const data = {
        uuid: uuid
    }
    POST(`/api/v1/chat/calls`, data, onSuccess, onError)
}

export const getCallsForCurrentUserApiCall = (onSuccess, onError) => {
    source = GET(`/api/v1/chat/calls`, onSuccess, onError, true, source)
}

export const deleteCallAPICall = (callId, onSuccess, onError) => {
    DELETE(`/api/v1/chat/calls/delete?uuid=${callId}`, onSuccess, onError, true, source)
}

export const transcribeAPICall = (formData, onSuccess, onError) => {
    POST(`/api/v1/chat/audio/transcribe`, formData, onSuccess, onError)
}

export const speakAudioAPICall = (text, onSuccess, onError) => {
    const data = {
        text: text
    }
    POST_BLOB(`/api/v1/chat/audio/speak`, data, onSuccess, onError, true, null, true)
}