import styled from "styled-components"
import { colors } from "../../Assets/styles/colors"
import { ReactComponent as CheckSvg } from "../../Assets/icons/subscription/checkWhiteInside.svg";
import { ReactComponent as CheckEmptySvg } from "../../Assets/icons/CheckEmpty.svg";

export const SubscriptionPlan = ({
    price,
    priceAditionalInfo,
    aditionalInfo,
    aditionalInfoOffer,
    payment,
    value,
    selectedValue,
    handleClick,
    differentOffer
}) => {
    return (
        <SubscriptionPlanWrapper onClick={() => handleClick()} selected={selectedValue === value} >
            <PriceWrapper>
                {priceAditionalInfo &&
                    <PriceAditionalInfo>
                        {differentOffer ?
                            priceAditionalInfo :
                            <s>
                                {priceAditionalInfo}
                            </s>}

                    </PriceAditionalInfo>
                }

                {differentOffer ?
                    <Price differentOffer={differentOffer}>
                        {price}
                    </Price>

                    :

                    <Price>
                        $  {price} {payment && <span>/ {payment}</span>}
                    </Price>}

            </PriceWrapper>

            <AditionalPriceInfoWrapper>
                <PriceAditionalInfo>{aditionalInfo}</PriceAditionalInfo>
                {aditionalInfoOffer && <PriceAditionalInfoOffer><p>{aditionalInfoOffer}</p></PriceAditionalInfoOffer>}
            </AditionalPriceInfoWrapper>
            <Separator />
            <CheckWrapper>
                {selectedValue === value ?
                    <CheckSvg
                        style={{ width: "20px", }}
                    /> : <CheckEmptySvg
                        style={{ width: "20px", }}
                    />}

            </CheckWrapper>
        </SubscriptionPlanWrapper>

    )
}


const PriceWrapper = styled.div`
display:flex;
flex-direction:column;
gap:5px;
width:100%;
`
const PriceAditionalInfo = styled.p`
font-size: 12px;
line-height: 14px;
text-align: left;
color:${colors.modalInfoTextColor};

`
const Price = styled.h3`
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color:${colors.modalGreenColor};

${props => props.differentOffer ?

        `
font-size: 14px;
font-weight: 600;
line-height: 20px;
text-align: left;
`:
        `
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
`

    }
span{
font-weight:400;
font-size:14px;
}
`
const AditionalPriceInfoWrapper = styled.p`
display:flex;
flex-direction:column;
align-items:center;
min-width:70px;
gap:5px;
`
const Separator = styled.div`
min-width:2px;
min-height:50px;
background:rgba(46, 50, 62, 1);
display:flex;
`

const CheckWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;

`
const PriceAditionalInfoOffer = styled.div`
display:flex;
align-items:center;
background:#2F2F5F;
border-radius:25px;
padding:4px 6px;
p{
font-size: 12px;
font-weight: 600;
line-height: 16px;
text-align: center;
color:${colors.white};
}
`
const SubscriptionPlanWrapper = styled.div`
  display: flex;
  padding:0 16px;
  border-radius: 10px;
  background: ${props => props.selected ? colors.modalDarkPurpleBg : "transparent"};
  width: 100%;
  align-items: center;
  gap:20px;
  min-height:74px;
  cursor:pointer;
  border:${props => props.selected ? `1px solid ${colors.purple}` : ""};

`;

