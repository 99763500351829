import { useEffect, useRef, useState } from "react"
import { useCallsService } from "../../Context/CallsContext";
import { cloneDeep } from "lodash";

const TranslatedMessageSpeaker = ({ speakMessagesList }) => {

    const { speakAudio } = useCallsService();

    const [audioFiles, setAudioFiles] = useState([]);
    const speakMessageIndexRef = useRef(0)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [audioCounter, setAudioCounter] = useState(0)

    const audioFilesRef = useRef([]);


    const handleSpeak = (text) => {
        speakAudio(
            text,
            (response) => {
                const apiResponse = response.data;
                const url = URL.createObjectURL(apiResponse);
                audioFilesRef.current.push(url)
                setAudioFiles(audioFilesRef.current);
                setAudioCounter(audioCounter + 1);
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handleEnd = () => {
        if (currentIndex < audioFiles.length) {
            let newIndex = currentIndex + 1
            setCurrentIndex(newIndex)
        }
    };

    const handlePlay = (audioFile) => {
        if (!audioFile) {
            return
        }
        const audio = new Audio(audioFile);
        audio.play();
        audio.onended = () => {
            handleEnd();
        }
    }


    useEffect(() => {
        if (speakMessagesList.length == 0) {
            speakMessageIndexRef.current = 0
            return
        }
        let speakMessakeListCopy = cloneDeep(speakMessagesList)
        speakMessakeListCopy.reverse();
        let speakItem = speakMessakeListCopy[speakMessageIndexRef.current];
        if (speakItem) {
            handleSpeak(speakItem.translation);
            speakMessageIndexRef.current = speakMessageIndexRef.current + 1;
        }

    }, [speakMessagesList])



    useEffect(() => {

        if (audioCounter > 0 &&
            (currentIndex < audioCounter)
        ) {
            handlePlay(audioFiles[currentIndex])
        }


    }, [currentIndex,
        audioCounter
    ]);



}

export default TranslatedMessageSpeaker