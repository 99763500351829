import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as ValidIcon } from "../../Assets/icons/subscription/check.svg"
import { ReactComponent as NoValidIcon } from "../../Assets/icons/subscription/noCheck.svg"

const BenefitElement = ({ title, subtitle, free, pro }) => {

    const displayValid = (valid) => {
        if (valid) {
            return <ValidIcon />
        } else {
            return <NoValidIcon />
        }
    }
    return (
        <Wrapper>
            <Info>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Info>
            <MarkCell>
                {displayValid(free)}
            </MarkCell>
            <MarkCell>
                {displayValid(pro)}
            </MarkCell>

        </Wrapper>
    )
}

export default BenefitElement

const Wrapper = styled.div`
display:flex;
align-items:center;
width:100%;
padding: 10px 0;
`
const Info = styled.div`
display:flex;
flex-direction:column;
gap:5px;
width:100%;
min-width:200px;
`
const Title = styled.h3`
font-size: 14px;
font-weight: 600;
line-height: 16px;
text-align: left;
color:${colors.white};
`
const Subtitle = styled.p`
font-size: 12px;
font-weight: 400;
line-height: 14px;
text-align: left;
color:${colors.modalInfoTextColor};
`
const MarkCell = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
padding:5px 10px;
border-left:1px solid rgba(46, 50, 62, 1);
`