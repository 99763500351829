import { DELETE, GET, POST } from "../API";


export const hadFreeTrialAPICall = (onSuccess, onError) => {
    GET(`/api/v1/payments/had_free_trial`, onSuccess, onError);
}

export const getStripePaymentLinkAPICall = (type, onSuccess, onError) => {
    const data = {
        type: type
    }
    POST(`/api/v1/payments/stripe_payment_link`, data, onSuccess, onError);
}

export const cancelStripeSubscriptionAPICall = (onSuccess, onError) => {
    DELETE(`/api/v1/payments/cancel_stripe_subscription`, onSuccess, onError);
}