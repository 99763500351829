import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import AuthContextProvider from "./Context/AuthContext";
import { CookiesProvider } from "react-cookie";
import LinksquaredContextProvider from "./Context/LinksquaredContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LinksquaredContextProvider>
    <AuthContextProvider>
      <HashRouter>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <App />
        </CookiesProvider>
      </HashRouter>
    </AuthContextProvider>
  </LinksquaredContextProvider>
);
