import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as Arrow } from "../../Assets/icons/dropDownArrow.svg";
import { useAuthService } from "../../Context/AuthContext";

import { notifyError } from "../../Helpers/Notifications";
import { localizationStrings } from "../../Localization/Localization";
import { useLinksquaredService } from "../../Context/LinksquaredContext";
import { reject } from "lodash";

const LinksquaredMessageModal = ({
  handleClose,
  handleItemClick,
  setLoading,
  loading,
}) => {
  const { linksquared } = useLinksquaredService();

  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const modalRef = useRef(null); // Reference to the modal container

  const getLinksquaredNotifications = (page) => {
    return new Promise((resolve, reject) => {
      linksquared.current.getMessages(
        page,
        (response) => {
          resolve(response.notifications);
        },
        (error) => {
          notifyError(`${localizationStrings.something_wrong}`);
          console.log(error);
          reject(error);
        }
      );
    });
  };

  const fetchMoreItems = async () => {
    if (loading) return; // Prevent multiple requests at the same time
    setLoading(true);
    try {
      // Mock API call (replace this with your real API call)
      const newItems = await getLinksquaredNotifications(page);
      setNotifications((prevItems) => [...prevItems, ...newItems]); // Append new items
      if (newItems.length / 20 !== 0) {
        setPage((prevPage) => prevPage + 1); // Increment the page number
      }
    } catch (error) {
      console.error("Error fetching more items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    const modal = modalRef.current;

    if (modal) {
      const { scrollTop, scrollHeight, clientHeight } = modal;

      // Check if the user has scrolled halfway
      if (scrollTop + clientHeight === scrollHeight) {
        fetchMoreItems();
      }
    }
  };

  useEffect(() => {
    fetchMoreItems();
  }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        // 👇️ your logic here
        handleClose();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <FullScreenItem onClick={() => handleClose()}>
      <Card>
        <Header>
          <h1>Messages</h1>
          <CloseBtn onClick={() => handleClose()}>
            <CloseIcon />
          </CloseBtn>
        </Header>

        {notifications.length > 0 ?
          <MessageList onScroll={handleScroll} ref={modalRef}>
            {notifications.map((item, index) => (
              <MessageItem key={index} onClick={() => handleItemClick(item)}>
                {!item.read && <Unread />}
                <Group>
                  <Title>{item.title}</Title>
                  <SubTitle>{item.subtitle}</SubTitle>
                </Group>
                <Arrow style={{ rotate: "-90deg" }} />
              </MessageItem>
            ))}
          </MessageList>
          :
          <NoMessageWrapper>
            There are currently no messages.
          </NoMessageWrapper>
        }
      </Card>
    </FullScreenItem>
  );
};

export default LinksquaredMessageModal;

const FullScreenItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 1;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 70%;
  height: 70%;
  z-index: 5;
  align-items: center;
  position: relative;
  gap: 20px;
  background: rgba(0, 0, 0, 0.9);
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  backdrop-filter: blur(10px);
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  h1 {
    font-size: 24px;
    font-weight: 600;
    padding-left: 10px;
    color: ${colors.white};
  }
`;
const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  max-width: 32px;
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
`;

const MessageItem = styled.div`
  padding: 20px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  position: relative;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 10px;
  color: white;
`;
const SubTitle = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
  color: rgba(255, 255, 255, 0.5);
`;
const Unread = styled.div`
  min-width: 10px;
  min-height: 10px;
  position: absolute;
  top: 31px;
  background: #007aff;
  border-radius: 50px;
  margin-left: -20px;
`;

const NoMessageWrapper = styled.div`
display:flex;
margin:auto 0;
font-size:18px;

color:${colors.white};
`