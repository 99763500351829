import React from 'react'
import styled from 'styled-components';
import { ReactComponent as WelcomIcon } from "../../Assets/icons/welcomeIcon.svg";
import { ReactComponent as WelcomAsset1 } from "../../Assets/icons/welcomeComponent/calls-selected.svg";
import { ReactComponent as WelcomAsset2 } from "../../Assets/icons/welcomeComponent/chats-selected.svg";
import { ReactComponent as WelcomAsset3 } from "../../Assets/icons/welcomeComponent/supportive1.svg";

import { colors } from '../../Assets/styles/colors';
import LocalStorage from '../../Helpers/LocalStorage';


const WelcomeComponent = ({ handleContinue }) => {
    const handleClick = () => {
        LocalStorage.setFirstWelcomeMessageRead(true)
        handleContinue()

    }
    return (
        <Container>
            <Content>
                <WelcomeWrapper >
                    <WelcomIcon />
                </WelcomeWrapper>
                <Group>
                    <Title>Welcome to <Gradient>emotii</Gradient></Title>
                    <Subtitle>Break language barriers and connect authentically with people from different cultures, spiecialties, and languages.</Subtitle>
                </Group>
                <Line>
                    <IconContainer>
                        <WelcomAsset1 />
                    </IconContainer>
                    <Infotext><span>Real-Time Voice Translations</span>: Speak naturally, we translate as you talk.</Infotext>
                </Line>
                <Line>
                    <IconContainer>
                        <WelcomAsset2 />
                    </IconContainer>
                    <Infotext><span>Supports 120+ Languages</span>: Communicate effortlessly in your preferred language.</Infotext>
                </Line>
                <Line>
                    <IconContainerSpecial>
                        <WelcomAsset3 />
                    </IconContainerSpecial>
                    <Infotext><span>Beyond Translation</span>: Build genuine understanding and better connections worldwide.</Infotext>
                </Line>


                <Group style={{ paddingTop: "50px" }}>
                    <Subtitle>Tap "Get Started" to begin your journey towards seamless global communication!</Subtitle>
                    <LoginButton
                        style={{ width: "100%" }}
                        id="sign-in-button"
                        onClick={(e) => handleClick(e)}
                    >
                        Get Started
                    </LoginButton>
                </Group>
            </Content>
        </Container>
    )
}

export default WelcomeComponent
const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow:hidden;
  background:#1A1D24;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction:column;
  padding: 0 32px;
  max-width:500px;
  margin:0 auto;
  overflow:auto;
  gap:30px;
`;

const Line = styled.div`
display:flex;
gap:20px;
align-items:center;
width:100%;


`
const IconContainer = styled.div`
svg{
min-width:40px;
min-height:40px;
}
`

const Title = styled.h1`
 color: ${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: center;
    `
const Subtitle = styled.p`
color: ${colors.chatElementColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    `

const Infotext = styled.p`

    color: ${colors.white};
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

    span{
    font-weight:600;
    }
    `

const WelcomeWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    `

const Group = styled.div`
display: flex;
  flex-direction: column;
  align-items:center;
  gap: 10px;
  width:100%;
    `

const Gradient = styled.span`
     background: linear-gradient(90deg,  #A354F4 5.1%, #0B9CF6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    
    `
const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
const IconContainerSpecial = styled.div`
min-width:40px;
display:flex;
align-items:center;
justify-content:center;
svg{

width:30px;
height:30px;
}
`