import React, { useRef, useState } from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as CalendarIcon } from "../../Assets/icons/calendarIcon.svg";
import { localizationStrings } from "../../Localization/Localization";
const BirthdayMenu = ({ value, setValue, format, slim, onboardStyle }) => {

    const handleCLick = () => {
        setIsMenuOpen(!menuOpen)
    }
    const [menuOpen, setIsMenuOpen] = useState(false);

    return (
        <Container slim={slim}>
            <DatePicker
                id="calendar"
                format={format ? format : "dd/MM/yyyy"}
                value={value}
                onChange={setValue}
                clearIcon={false}
                isOpen={menuOpen}
                openCalendarOnFocus={true}
                maxDate={new Date()}
                calendarIcon={<CalendarIcon />
                }
            >
            </DatePicker>

            {!value &&
                <PlaceHolderWrapper onClick={() => handleCLick()} onboardStyle={onboardStyle}>
                    <p>{localizationStrings.profile.select_birthdate}</p>
                </PlaceHolderWrapper>
            }

        </Container >
    );
};

export default BirthdayMenu;
const Container = styled.div`
  display: flex;
  width: 100%;
  z-index:2;
position:relative;
  .react-date-picker{
    width:100%;
    color:${colors.white};
    
  }

  .react-date-picker__wrapper {
    width: 100%;
    border: none;
    padding: ${props => props.slim ? "0" : "14px 16px "};
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    
  }
  .react-date-picker react-date-picker--closed react-date-picker--enabled{
    width:100%;
  }
  .react-date-picker__inputGroup__input{
   
   
  }
.react-calendar{
    background: ${colors.newChatModalInputBg};
    border:none;
    border-radius:10px;
}
.react-calendar__month-view__days__day{
    color:white;
}
.react-calendar__month-view__days__day--weekend{
    color:red;
}
.react-calendar__tile{
    border-radius:10px;
}
.react-calendar__tile--active{
background:${colors.purple};
}
.react-calendar__navigation button{
    color:${colors.purple};

}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
    background:${colors.background};
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
    background:${colors.background};
}
.react-calendar__year-view__months__month{
    color:${colors.white};
}
.react-calendar__tile--hasActive{
    background:${colors.purple};
}
.react-date-picker__button svg{
    stroke:${colors.purple};
}
.react-calendar__tile--now {
    background: #1A1D24;
}
.react-calendar__tile:disabled {
    opacity:0.3;
    background-color:transparent;
}
.react-calendar__navigation button:disabled{
    opacity:0.3;
    background-color:unset;
}
`;

const PlaceHolderWrapper = styled.div`
display:flex;
position:absolute;
background:${colors.newChatModalInputBg};
top:0;
bottom:0;
align-items:center;
border-radius:10px;
padding:${props => props.onboardStyle ? "0 16px" : 0};
p{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${colors.white};
}
`