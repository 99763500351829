import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import QuickChatHeader from "./QuickChatHeader";
import QcChatComponent from "./QcChatComponent";
import { useQuickChatService } from "../../Context/QuickChatContext";
import { notifyError } from "../../Helpers/Notifications";
import {
  handleConvertBitesToMb,
  handleReceivedMessages,
  sortReceivedMessages,
} from "../../Helpers/chatHelper/ChatHelper";
import moment from "moment";
import FullscreenImage from "../Chat/FullscreenImage";
import { mapEmoji } from "../../Constants/emojiList";
import WebSocketObserver from "../../Helpers/websocket/WebSocketObserver";

import { useLocation } from "react-router";
import { useQcWebSocketDataService } from "../../Context/QuickChatWebsocketContext";
import { isMobile, isSafari } from "react-device-detect";
import requestPermission from "../../Helpers/RequestMessagingPermision";
import { NEUTRAL_MOOD } from "../../Constants/constantValues";
import { last } from "lodash";
import { useAuthService } from "../../Context/AuthContext";


const QuickChat = ({ setLoading }) => {

  const {
    quickChat,
    getMessagesQuickChat,
    addMessagesQuickChat,
    editMessageQuickChat,
    replyMessageQuickChat,
    reactMessageQuickChat,
    markAsReadMessageQuickChat
  } = useQuickChatService();


  const { add_observer, remove_observer, getQcWebsocketUrl } = useQcWebSocketDataService();

  const [messages, setMessages] = useState([]);
  const [fullScreenImg, setFullScreenImg] = useState(null);
  const [showFullScreenImg, setShowFullScreenImg] = useState(false);
  const [messageMood, setMessageMood] = useState(NEUTRAL_MOOD)

  const messagePageRef = useRef(1);
  const currentMessagesRef = useRef([]);
  const shouldScrollBottom = useRef(true);
  const shouldScroolToCurrentMessage = useRef(false);
  const shouldLoadMoreMessages = useRef(true);
  const scollHeightRef = useRef(0);
  const location = useLocation();

  const checkIfShouldScroll = () => {
    let element = document.getElementById("qcMessageList");

    let scrollTop = element.scrollTop;

    if (scrollTop == 0) {

      return true;
    } else {

      return false;
    }
  };

  const MessagesChannelObserver = new WebSocketObserver(
    "MessagesChannel",
    (message) => {

      const response = JSON.parse(message);
      if (response) {
        if (response.conversation_id === quickChat.id) {
          shouldScrollBottom.current = checkIfShouldScroll();
          if (response.reactions.length > 0) {
            shouldScrollBottom.current = false;
          }

          shouldScroolToCurrentMessage.current = false;
          const newMessages = handleReceivedMessages(currentMessagesRef.current, response);
          const sorted = sortReceivedMessages(newMessages);
          currentMessagesRef.current = sorted;
          setMessages(sorted);
        }

      }
    }
  );



  const handleReceivedMessagesResponse = (newMessages, page) => {
    let messages = [];
    if (page !== 1) {
      shouldScrollBottom.current = false;
      shouldScroolToCurrentMessage.current = true;
      messages = handleReceivedMessages(
        currentMessagesRef.current,
        newMessages
      );
    } else {
      messages = handleReceivedMessages([], newMessages);
    }
    const sortedMessages = sortReceivedMessages(messages);
    currentMessagesRef.current = sortedMessages;
    setMessages(currentMessagesRef.current);
  };

  const handleEditMessageResponse = (message) => {
    let messages = currentMessagesRef.current.map((item) => {
      if (item.id === message.id) {
        return message;
      } else {
        return item;
      }
    });

    currentMessagesRef.current = messages;
    setMessages(currentMessagesRef.current);
  };

  const handleGetMessages = (chatId, page) => {
    getMessagesQuickChat(
      chatId,
      page,
      (response) => {
        const responseMessages = response.data.messages;
        shouldLoadMoreMessages.current = !(response.data.messages.length < 20);
        handleReceivedMessagesResponse(responseMessages, page);
      },
      (error) => {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const handleSendMessage = (messageInput, onSuccess) => {
    if (!messageInput.replace(/\s/g, "").length) {
      return;
    }

    let nowDate = moment().toISOString();
    let dataObject = new FormData();
    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", messageInput);
    dataObject.append("mood", messageMood);

    addMessagesQuickChat(
      dataObject,
      quickChat.id,
      (response) => {
        const newMessage = handleReceivedMessages(
          currentMessagesRef.current,
          response.data.message
        );
        const sorted = sortReceivedMessages(newMessage);
        currentMessagesRef.current = sorted;
        shouldScrollBottom.current = true;
        shouldScroolToCurrentMessage.current = false;
        setMessages(currentMessagesRef.current);
        onSuccess();
      },
      (error) => {
        if (error.response?.data?.message == "Conversation not open") {
          notifyError(error.response.data.message);
        } else {
          console.log(error);
          notifyError("Something wrong");
        }

      }
    );
  };

  const handleReplyMessage = (
    messageInput,
    conversationId,
    messageId,
    onSuccess,
  ) => {

    if (!messageInput.replace(/\s/g, "").length) {
      return;
    }

    let nowDate = moment().toISOString();
    let dataObject = new FormData();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", messageInput);
    dataObject.append("mood", messageMood);

    replyMessageQuickChat(
      dataObject,
      conversationId,
      messageId,
      (response) => {
        const newMessages = handleReceivedMessages(
          currentMessagesRef.current,
          response.data.message
        );
        const sorted = sortReceivedMessages(newMessages);
        currentMessagesRef.current = sorted;


        shouldScrollBottom.current = true;
        shouldScroolToCurrentMessage.current = false;
        setMessages(currentMessagesRef.current);
        onSuccess();
      },
      (error) => {
        notifyError("Something wrong");
      }
    );
  };

  const handleSendMedia = (type, file, text, thumbnail, onSuccess, onError) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("mood", messageMood);

    if (type === "image") {
      dataObject.append("image", file);
    }

    if (type === "video") {
      dataObject.append("video", file);
      dataObject.append("video_thumbnail", thumbnail);
    }

    if (text.length > 0) {
      dataObject.append("original_message", text);
    }

    addMessagesQuickChat(
      dataObject,
      quickChat.id,
      function (response) {
        const sortedMessages = sortReceivedMessages(
          handleReceivedMessages(
            currentMessagesRef.current,
            response.data.message
          )
        );
        currentMessagesRef.current = sortedMessages;
        shouldScrollBottom.current = true;
        shouldScroolToCurrentMessage.current = false;
        setLoading(false);
        setMessages(currentMessagesRef.current);
        onSuccess();
      },
      function (error) {
        onError();
        console.log(error, "error");
        setLoading(false);
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
      }
    );
  };

  const handleReplyWithMedia = (
    conversationId,
    messageId,
    type, file, text, thumbnail,
    callback
  ) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("mood", messageMood);

    if (text.length > 0) {
      dataObject.append("original_message", text);
    }
    if (type === "image") {
      dataObject.append("image", file);
    }

    if (type === "video") {
      dataObject.append("video", file);
      dataObject.append("video_thumbnail", thumbnail);
    }

    replyMessageQuickChat(
      dataObject,
      conversationId,
      messageId,
      function (response) {
        const sortedMessages = sortReceivedMessages(handleReceivedMessages(currentMessagesRef.current, response.data.message));
        currentMessagesRef.current = sortedMessages;
        callback();
        setLoading(false);
        shouldScrollBottom.current = true;
        shouldScroolToCurrentMessage.current = false;
        setMessages(sortedMessages);
      },
      function (error) {
        callback();
        console.log(error, "error");
        setLoading(false);
      }
    );
  };

  const handleSendFile = (file, text, onSuccess, onError) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("document", file);
    dataObject.append("mood", messageMood);

    if (text.length > 0) {
      dataObject.append("original_message", text);
    }

    addMessagesQuickChat(
      dataObject,
      quickChat.id,
      function (response) {
        const sortedMessages = sortReceivedMessages(
          handleReceivedMessages(
            currentMessagesRef.current,
            response.data.message
          )
        );
        currentMessagesRef.current = sortedMessages;

        shouldScrollBottom.current = true;
        shouldScroolToCurrentMessage.current = false;
        setLoading(false);
        onSuccess();
        setMessages(currentMessagesRef.current);
      },
      function (error) {
        onError();
        console.log(error, "error");
        setLoading(false);
      }
    );
  };

  const handleReplyWithFile = (
    conversationId,
    messageId,
    file,
    text,
    callback,
  ) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("document", file);
    dataObject.append("mood", messageMood);

    if (text.length > 0) {
      dataObject.append("original_message", text);
    }

    replyMessageQuickChat(
      dataObject,
      conversationId,
      messageId,
      function (response) {
        const sortedMessages = sortReceivedMessages(
          handleReceivedMessages(
            currentMessagesRef.current,
            response.data.message
          )
        );
        currentMessagesRef.current = sortedMessages;
        callback();
        shouldScrollBottom.current = true;
        shouldScroolToCurrentMessage.current = false;
        setLoading(false);
        setMessages(sortedMessages);
      },
      function (error) {
        callback()
        console.log(error, "error");
        setLoading(false);
      }
    );
  };


  const handleEditMessage = (chatId, messageId, message, onSuccess) => {
    editMessageQuickChat(
      chatId,
      messageId,
      message,
      (response) => {
        handleEditMessageResponse(response.data.message);
        onSuccess();
      },
      (error) => {
        notifyError("Something wrong");
      }
    );
  };

  const handleReactToMessage = (reactionText, conversationId, messageId, onSuccess) => {
    let reaction = mapEmoji(reactionText);
    if (!reaction) {
      return;
    }
    reactMessageQuickChat(
      reaction,
      conversationId,
      messageId,
      function (response) {
        onSuccess && onSuccess()
      },
      function (error) {
        console.log(error);
      }
    );
  };

  const markMessagesRead = (messages) => {
    let newMessages = messages.map((message) => {
      if (message.user_id !== quickChat.anonymous_users[0].id) {
        if (message.read === false) {
          markAsReadMessageQuickChat(
            message.conversation_id,
            message.id,
            function (response) {
            },
            function (error) {
              console.log(error, "error mark as read");
            }
          );
        }
      }
    });
    return newMessages;
  };



  const handleGetMoreMessages = (e) => {

    if (shouldLoadMoreMessages.current) {
      messagePageRef.current += 1;
      handleGetMessages(quickChat.id, messagePageRef.current);
    }

  };

  const handleShowFullScreenImg = (image, messageId, conversationId) => {
    setFullScreenImg({
      image: image,
      messageId: messageId,
      conversationId: conversationId,
    });
    setShowFullScreenImg(true);
  };

  const handleCloseFullScreenImg = () => {
    setShowFullScreenImg(false);
    setFullScreenImg(null);
  };

  const initializeConversationMood = (messages) => {
    const user = quickChat.anonymous_users[0]

    let currentUsermessages = messages.filter((message) => message.user_id === user.id)
    let lastMessageMood = last(currentUsermessages)?.mood

    if (lastMessageMood) {
      setMessageMood(lastMessageMood)
    } else {
      setMessageMood(NEUTRAL_MOOD)
    }

  }

  useEffect(() => {
    if (!quickChat) {
      return
    }

    handleGetMessages(quickChat.id, 1);

    getQcWebsocketUrl(
      quickChat.id,
      (response) => {
      },
      (error) => {
        console.log(error)
      }
    )

  }, [quickChat.id]);

  var lastFired = new Date().getTime();

  setInterval(function () {
    const now = new Date().getTime();
    if (now - lastFired > 2000) {//if it's been more than 2 seconds

      handleGetMessages(quickChat.id, 1);
      getQcWebsocketUrl(
        quickChat.id,
        (response) => {


        },
        (error) => {
          console.log(error)
        }
      )

    }
    lastFired = now;
  }, 500);


  useEffect(() => {
    markMessagesRead(currentMessagesRef.current)
    initializeConversationMood(currentMessagesRef.current)
  }, [messages.length])

  useEffect(() => {
    add_observer(MessagesChannelObserver);
    return () => {
      remove_observer(MessagesChannelObserver);
    };
  }, [])


  return (
    <FullWrapper>
      <FirstWrapper id="firstWrapper">
        <ChatWrapper >
          <QuickChatHeader />
          <QcChatComponent
            messages={messages}
            page={messagePageRef}
            user={quickChat.anonymous_users[0]}
            selectedConversation={quickChat}
            handleSendMessage={handleSendMessage}
            handleSendMedia={handleSendMedia}
            handleSendFile={handleSendFile}
            shouldScroolToCurrentMessage={shouldScroolToCurrentMessage}
            shouldScrollBottom={shouldScrollBottom}
            handleGetMoreMessages={handleGetMoreMessages}
            scollHeightRef={scollHeightRef}
            handleShowFullScreenImg={handleShowFullScreenImg}
            handleEditMessage={handleEditMessage}
            handleReplyMessage={handleReplyMessage}
            handleReactToMessage={handleReactToMessage}
            handleReplyWithMedia={handleReplyWithMedia}
            handleReplyWithFile={handleReplyWithFile}
            shouldLoadMoreMessages={shouldLoadMoreMessages}
            messageMood={messageMood}
            setMessageMood={setMessageMood}
          />
        </ChatWrapper>

        {showFullScreenImg && (
          <FullscreenImage
            selectedConversation={quickChat}
            image={fullScreenImg.image}
            conversationId={fullScreenImg.conversationId}
            messageId={fullScreenImg.messageId}
            handleCloseFullScreenImg={handleCloseFullScreenImg}
          />
        )}
      </FirstWrapper>
    </FullWrapper>
  );
};

export default QuickChat;

const FullWrapper = styled.div`
  background: ${colors.quickChatClosedBg};

`;

const FirstWrapper = styled.div`
  display: flex;
  flex-direction: column;
   max-height: 100svh;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  position:relative;
  @media (max-width: 1440px) {
    margin: 0;
  }
  overflow: hidden;
`;

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100%;
  overflow: hidden;
`;
