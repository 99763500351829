import { POST } from "../API"
let source;
export const addContactAPI = (name, phoneNumber, email, onSuccess, onError) => {

    const data = {
        name: name,
        phone_number: phoneNumber,
        email: email,
    }
    source = POST(`/api/v1/chat/contacts/add_contact`, data, onSuccess, onError, true, source)
}