import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { displayAvatar } from "../../Helpers/chatHelper/AvatarHelper";
import ControlButton from "./ControlButton";

import { ReactComponent as MicOff } from "../../Assets/icons/calls/micOn.svg";
import { ReactComponent as TranslationIcon } from "../../Assets/icons/calls/translationIcon.svg";
import { ReactComponent as MuteSound } from "../../Assets/icons/calls/muteSound.svg";
import { ReactComponent as CloseCall } from "../../Assets/icons/calls/closeCall.svg";
import { ReactComponent as ManualTranslationActive } from "../../Assets/icons/calls/manualRecordingActive.svg";
import { ReactComponent as ManualTranslationInactive } from "../../Assets/icons/calls/manualRecordingInactive.svg";

import { displayName } from "../../Helpers/chatHelper/NameHelper";
import callingSound from "../../Assets/sounds/ring.wav";
import connectedSound from "../../Assets/sounds/connected.mp3";
import { Howl, Howler } from "howler";

import {
  LiveKitRoom,
  useRoomContext,
  RoomAudioRenderer,
  useRemoteParticipants,
  useConnectionState,
  useLocalParticipant,
} from "@livekit/components-react";

import { useWebSocketDataService } from "../../Context/WebsocketContext";
import SpeechContainer from "../AudioVideoCallsSpeechRecognition/SpeechContainer";
import TranslatedMessageSpeaker from "./TranslatedMessageSpeaker";
import RecorderService from "./RecorderService";
import { AUTO, MANUAL } from "../../Constants/constantValues";

export const callingSoundHowl = new Howl({
  src: [callingSound],
  loop: true,
});

export const connectedSoundHowl = new Howl({
  src: [connectedSound],
});

const MyComponent = ({
  data,
  resetCall,
  sendCloseCallMessage,
  speechRecongnitionMessages,
  translationEnabled,
  handleSendRequestTranslationMessage,
  handleCloseRequestCallTranslationModal,
  handleTranscribeAudio,
  speakMessagesList,
}) => {
  const room = useRoomContext();
  const remoteParticipant = useRemoteParticipants();
  const localParticipant = useLocalParticipant();

  const [manualRecordingActive, setManualRecordingActive] = useState(false);
  const [speechRecongnitionMode, setSpeechRecognitionMode] = useState(AUTO);

  const [micEnabled, setMicEnabled] = useState(
    room.localParticipant.isMicrophoneEnabled
  );

  const [audioEnabled, setAudioEnabled] = useState(false);
  const recorderServiceRef = useRef(new RecorderService());
  const spacePressRef = useRef(false);

  const togleMic = () => {
    const enabled = room.localParticipant.isMicrophoneEnabled;
    room.localParticipant.setMicrophoneEnabled(!enabled);
    setButtons();
  };

  const togleAudio = () => {
    const localRemoteParticipant = remoteParticipant[0];
    const audioPublication =
      localRemoteParticipant?.getTrackPublications("audio")[0];
    if (audioPublication !== undefined) {
      audioPublication.audioTrack?.setMuted(audioEnabled);
    }
  };

  const setButtons = () => {
    const enabled = room.localParticipant.isMicrophoneEnabled;
    setMicEnabled(enabled);
  };

  const handleDisconect = (e) => {
    if (room?.state === "connecting") {
      return;
    }

    e.preventDefault();
    sendCloseCallMessage(data.uuid);
    callingSoundHowl.stop();
    room.disconnect();
    resetCall();
  };

  const displayStatus = () => {
    if (remoteParticipant.length == 0) {
      return "Connecting";
    }

    if (remoteParticipant.length > 0) {
      return "Connected";
    }
  };

  const blobToFile = (theBlob, fileName) => {
    let file = new File([theBlob], fileName, {
      lastModified: new Date(),
      type: "audio/mp3",
    });
    return file;
  };

  const handleRequestTranslation = () => {
    if (!translationEnabled) {
      handleSendRequestTranslationMessage();
    } else {
      handleCloseRequestCallTranslationModal();
    }
  };

  const startRecorderService = async () => {
    if (micEnabled) {
      return;
    }
    await recorderServiceRef.current.start((blob) => {
      const audioFile = blobToFile(blob, "audio.mp3");
      handleTranscribeAudio(audioFile);
    });
  };

  const stopRecorderService = async () => {
    await recorderServiceRef.current.stop();
  };

  const handleSpaceDown = (event) => {
    if (!spacePressRef.current) {
      spacePressRef.current = true;
      if (event.code === "Space") {
        event.preventDefault();
        setManualRecordingActive(true);
        startRecorderService();
      }
    }
  };

  const handleSpaceUp = (event) => {
    spacePressRef.current = false;
    if (event.code === "Space") {
      event.preventDefault();
      setManualRecordingActive(false);
      stopRecorderService();
    }
  };

  const handleRecordingBtnPress = () => {
    setManualRecordingActive(true);
    startRecorderService();
  };
  const handleRecordingBtnRelease = () => {
    setManualRecordingActive(false);
    stopRecorderService();
  };

  useEffect(() => {
    // Initialize the RecorderService when the component mounts
    recorderServiceRef.current.init();
    // Cleanup when the component unmounts
    return () => {
      // stopRecorderService();
      recorderServiceRef.current.cleanup();
    };
  }, []);

  useEffect(() => {
    if (translationEnabled) {
      startRecorderService();
    }
    return () => {
      stopRecorderService();
    };
  }, [translationEnabled]);

  useEffect(() => {
    if (callingSoundHowl.playing() === true && remoteParticipant.length > 0) {
      callingSoundHowl.stop();
      connectedSoundHowl.play();
    }
  }, [remoteParticipant]);

  useEffect(() => {
    togleAudio();
  }, [audioEnabled, remoteParticipant]);

  useEffect(() => {
    if (!translationEnabled) {
      return;
    }

    if (micEnabled || speechRecongnitionMode === MANUAL) {
      stopRecorderService();
    } else {
      startRecorderService();
    }
  }, [micEnabled, speechRecongnitionMode]);

  useEffect(() => {
    if (micEnabled || speechRecongnitionMode !== MANUAL) {
      return;
    }
    // Add event listeners for keydown and keyup when the component mounts
    window.addEventListener("keydown", handleSpaceDown);
    window.addEventListener("keyup", handleSpaceUp);

    // Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleSpaceDown);
      window.removeEventListener("keyup", handleSpaceUp);
    };
  }, [speechRecongnitionMode, micEnabled]);

  useEffect(() => {
    return () => {
      if (room) {
        sendCloseCallMessage(data.uuid);
        callingSoundHowl.stop();
        room.disconnect();
        resetCall();
      }
    };
  }, []);
  return (
    <Content>
      <div id="participants-area"></div>
      <UserWrapper translationEnabled={translationEnabled}>
        <UserAvatar
          style={data?.quick_chat ? { border: "none" } : {}}
          translationEnabled={translationEnabled}
        >
          {displayAvatar(data)}
        </UserAvatar>
        <UserDetails translationEnabled={translationEnabled}>
          <UserName>{displayName(data)}</UserName>
          <ConnectionStatus translationEnabled={translationEnabled}>
            {displayStatus()}
          </ConnectionStatus>
        </UserDetails>
      </UserWrapper>

      {translationEnabled && (
        <SpeechContainer
          micEnabled={micEnabled}
          speechRecongnitionMessages={speechRecongnitionMessages}
          toggle={speechRecongnitionMode}
          setToggle={setSpeechRecognitionMode}
          manualRecordingActive={manualRecordingActive}
        />
      )}

      {translationEnabled && (
        <TranslatedMessageSpeaker speakMessagesList={speakMessagesList} />
      )}

      <ControlsWrapper
        style={
          translationEnabled
            ? {
                flexDirection: "row",
                gap: "30px",
                padding: "0 20px",
                justifyContent: "center",
              }
            : {}
        }
      >
        <ControlGroup>
          <ControlButton
            active={micEnabled}
            translationEnabled={translationEnabled}
            icon={<MicOff />}
            onClick={() => {
              togleMic();
            }}
          />
          <ControlButton
            active={audioEnabled}
            translationEnabled={translationEnabled}
            icon={<MuteSound />}
            onClick={() => {
              setAudioEnabled(!audioEnabled);
            }}
          />
          <ControlButton
            active={translationEnabled}
            translationEnabled={translationEnabled}
            icon={<TranslationIcon />}
            onClick={() => {
              handleRequestTranslation();
            }}
          />
          <ControlButton
            translationEnabled={translationEnabled}
            icon={<CloseCall />}
            onClick={(e) => {
              handleDisconect(e);
            }}
          />
        </ControlGroup>

        {/* <ControlGroup>
          <ControlButton
            translationEnabled={translationEnabled}
            icon={<CloseCall />}
            onClick={(e) => {
              handleDisconect(e);
            }}
          />
        </ControlGroup> */}

        {speechRecongnitionMode === MANUAL && (
          <ControlGroup style={{ marginLeft: "auto" }}>
            <InfoText>
              {manualRecordingActive
                ? "Recording and translating, release the button (or space bar) to finish."
                : "Keep the button pressed (or use the space bar) to translate your message"}
            </InfoText>
            <ControlButton
              style={{ background: "none", minWidth: "60px" }}
              active={micEnabled}
              translationEnabled={translationEnabled}
              icon={
                manualRecordingActive ? (
                  <ManualTranslationActive />
                ) : (
                  <ManualTranslationInactive />
                )
              }
              onMouseDown={handleRecordingBtnPress}
              onMouseUp={handleRecordingBtnRelease}
              onMouseLeave={handleRecordingBtnRelease}
            />
          </ControlGroup>
        )}
      </ControlsWrapper>
    </Content>
  );
};

const FullScreenCall = ({
  data,
  callAccess,
  resetCall,
  speechRecongnitionMessages,
  translationEnabled,
  handleSendRequestTranslationMessage,
  handleCloseRequestCallTranslationModal,
  handleTranscribeAudio,
  speakMessagesList,
}) => {
  const { sendCloseCallMessage } = useWebSocketDataService();

  return (
    <FullscreenContainer>
      <LiveKitRoom
        serverUrl={callAccess?.endpoint}
        token={callAccess?.token}
        audio={true}
        style={{ height: "100%", width: "100%" }}
      >
        <RoomAudioRenderer />
        <MyComponent
          data={data}
          resetCall={resetCall}
          sendCloseCallMessage={sendCloseCallMessage}
          speechRecongnitionMessages={speechRecongnitionMessages}
          translationEnabled={translationEnabled}
          handleSendRequestTranslationMessage={
            handleSendRequestTranslationMessage
          }
          handleCloseRequestCallTranslationModal={
            handleCloseRequestCallTranslationModal
          }
          handleTranscribeAudio={handleTranscribeAudio}
          speakMessagesList={speakMessagesList}
        />
      </LiveKitRoom>
    </FullscreenContainer>
  );
};

export default FullScreenCall;

const FullscreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${colors.background};
  z-index: 1;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  min-width: 500px;
  min-height: 600px;
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: ${(props) => (props.translationEnabled ? "45px" : "80px")};
  max-width: ${(props) => (props.translationEnabled ? "45px" : "80px")};
  object-fit: contain;
  position: relative;
  min-width: ${(props) => (props.translationEnabled ? "45px" : "80px")};
  min-height: ${(props) => (props.translationEnabled ? "45px" : "80px")};
  overflow: hidden;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    position: relative;
    height: ${(props) => (props.translationEnabled ? "45px" : "80px")};
    width: ${(props) => (props.translationEnabled ? "45px" : "80px")};
  }
`;

const UserName = styled.h1`
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
`;
const ConnectionStatus = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  color: ${colors.chatElementColor};
`;

const ControlGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 150px;
  width: 100%;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px;
  width: 100%;
  ${(props) =>
    props.translationEnabled &&
    `
  align-items: center;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  `}
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${(props) => (props.translationEnabled ? "start" : "center")};
  gap: ${(props) => (props.translationEnabled ? "5px" : "10px")};
  text-align: ${(props) => (props.translationEnabled ? "center" : "start")};
`;

const InfoText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.modalInfoTextColor};
  font-weight: 400;
  max-width: 300px;
`;
