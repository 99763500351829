
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { findTranslatedTextForOtherUser, findTranslatedTextForUser } from '../../Helpers/chatHelper/ChatHelper'
import { useAuthService } from '../../Context/AuthContext'

const SpeechMessage = ({ ownMessage, message, faded }) => {
    const { user } = useAuthService();
    const translationMessage = findTranslatedTextForUser((message.call_message_translations), user);
    const transaltedMessageForOtherUser = findTranslatedTextForOtherUser(message.call_message_translations, user)

    return (
        <Container ownmessage={ownMessage} faded={faded}>
            <OriginalMessage ownmessage={ownMessage}>{message.original_message}</OriginalMessage>
            <TranslatedMessage ownmessage={ownMessage}>
                {ownMessage ? transaltedMessageForOtherUser.translation : translationMessage?.translation}
            </TranslatedMessage>
        </Container>
    )
}

export default SpeechMessage
const Container = styled.div`
display:flex;
flex-direction:column;
max-width:400px;
margin-left:${props => props.ownmessage ? "auto" : ""};
gap:5px;
opacity:${props => props.faded ? 0.5 : 1};
`
const OriginalMessage = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: ${props => props.ownmessage ? "end" : "start"};
    color: ${colors.white};
`
const TranslatedMessage = styled.p`
 overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: ${props => props.ownmessage ? "end" : "start"};
    color: ${colors.purple};
`