export const filterConversations = (conversations, searchTerm) => {
    if (searchTerm === "") {
        return conversations
    } else {
        let filtered = [];
        conversations.map((conversation) => {
            conversation.other_participants.map((element) => {
                if (element.phone_number?.includes(searchTerm) ||
                    element.name.toLowerCase().includes(searchTerm.toLowerCase() ||
                        element.email?.toLowerCase().includes(searchTerm)
                    )
                ) {
                    filtered.push(conversation)
                }
            })
            if (conversation.group) {
                if (conversation.group.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    filtered.push(conversation)
                }
            }
            if (conversation.quick_chat) {
                if (conversation.quick_chat_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    filtered.push(conversation)
                }
            }
        })
        return [...new Set(filtered)];
    }
}

