import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as Crown } from "../../Assets/icons/subscription/crown.svg";
import { colors } from '../../Assets/styles/colors';
import LocalStorage from '../../Helpers/LocalStorage';
import moment from 'moment/moment';

const TryNowProCard = ({ setShowTryProCard, handleOpenSubscriptionModal, }) => {

    const handleClose = () => {
        LocalStorage.setTryProCard(moment())
        setShowTryProCard(false)
    }

    const handleClick = () => {
        handleOpenSubscriptionModal();
    }


    return (
        <Container >
            <CloseBtn onClick={handleClose}>
                <CloseIcon />
            </CloseBtn>
            <Line onClick={handleClick}>
                <Crown />
                <Title>Try now PRO Subscription</Title>
            </Line>
        </Container>
    )
}

export default TryNowProCard


const Container = styled.div`
display:flex;
flex-direction:column;
gap:20px;
background:${colors.newChatModalBg};
padding:20px;
border-radius:10px;
box-shadow: 0px 0px 11.3px 0px ${colors.modalDarkPurple};
position:relative;

`

const Line = styled.div`
display:flex;
align-items:center;
gap:10px;
cursor:pointer;
`
const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  max-width: 24px;
  position:absolute;
  top:-10px;
  right:-10px;

`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.white};
`;