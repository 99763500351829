import { POST } from "../API"
let source;
let fetchSource;
export const conversationWithPhoneNumberApi = (phoneNumber, onSuccess, onError) => {
    const data = {
        phone_number: phoneNumber
    }
    source = POST(`/api/v1/chat/conversations/with_phone_number`, data, onSuccess, onError, true, source)
}

export const fetchConversationAPICall = (data, onSuccess, onError) => {
    // const data = {
    //     phone_number: phoneNumber
    // }
    fetchSource = POST(`/api/v1/chat/conversations/fetch`, data, onSuccess, onError, true, fetchSource)
}

