import React from "react";
import LoginPage from "../Pages/LoginPage";
import { useAuthService } from "../Context/AuthContext";
import ElectronLoginPage from "../electron/ElectronLogin";
import { Navigate, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isElectron = !!window.electron;
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAuthService();
  if (user === undefined) return <div></div>;
  if (user === null) {
    if (isElectron) {
      return <ElectronLoginPage />;
    }
    return (
      <Navigate to={"/login"} state={{ backTo: `${location.pathname}` }} />
    );

  }

  return <>{children}</>;
};

export default PrivateRoute;
