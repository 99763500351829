import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";

const DisplayFullScreenMessage = ({ handleClose, message }) => {
  return (
    <FullScreenItem onClick={() => handleClose()}>
      <Container onClick={() => handleClose()}>
        <Header>
          <CloseBtn onClick={() => handleClose()}>
            <CloseIcon />
          </CloseBtn>
        </Header>
        <GeneratedPage src={`https://${message.access_url}`} />
      </Container>
    </FullScreenItem>
  );
};

export default DisplayFullScreenMessage;
const FullScreenItem = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  left: 0;
  top: 0;
  z-index: 20;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;
const Header = styled.div`
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  padding: 10px;
`;
const GeneratedPage = styled.iframe`
  height: 100%;
  width: 100%;
  overflow: auto;
  border: none;
`;
const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  max-width: 32px;
`;
