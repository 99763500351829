import { POST } from "../API"
let source;
export const translateMessageAPICall = (from, to, message, onSuccess, onError) => {

    const data = {
        from: from,
        to: to,
        message: message,
    }

    source = POST(`/api/v1/chat/translate/translate_message`, data, onSuccess, onError, true, source)
}