import { GET, POST } from "../API";
let source = null;

export const getCurrentUserApi = (onSuccess, onError) => {
    source = GET(`/api/v1/identity/users/current`, onSuccess, onError, true, source);
}

export const getCustomTokenAPICall = (onSuccess, onError) => {
    GET(`/api/v1/identity/users/authentication_token`, onSuccess, onError);
}

export const loginWithSSOAPICall = (onSuccess, onError) => {
    const data = {}
    POST(`/api/v1/identity/sso/auth/microsoft_office365`, data, onSuccess, onError)
}

export const loginWithGoogleAPICall = (onSuccess, onError) => {
    const data = {}
    POST(`/api/v1/identity/sso/auth/google_oauth2`, data, onSuccess, onError)
}