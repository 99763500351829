import { POST } from "../API"

export const forwardMessageApiCall = (conversation_ids, phone_numbers, emails, device_sent_date, messageId, onSuccess, onError) => {

    const data = {
        conversation_ids: conversation_ids,
        phone_numbers: phone_numbers,
        emails: emails,
        device_sent_date: device_sent_date,
        deleted: false
    }

    POST(`/api/v1/chat/conversations/messages/${messageId}/forward_message`, data, onSuccess, onError, true)
}